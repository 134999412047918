import "./Style.css";
import Logo from "./sparkle.gif";

const LogoPic = () => {
  return (
    <div>
      <nav className="navbody">
        <a className="navbar-brand" href="/">
          <img
            src={Logo}
            width="50"
            height="auto"
            className="logo"
            alt="Flower"
          />
          <span className="header-text">What's Your Pick?</span>
        </a>
      </nav>
    </div>
  );
};

export default LogoPic;
