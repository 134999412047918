// import Button from 'react-bootstrap/Button'
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Card from 'react-bootstrap/Card';
// import Alert from 'react-bootstrap/Alert';
// import { NavBar } from './components/NavBar';
import LogoPic from "./components/LogoPic";
import Main from "./components/Main";
import "./Style.css";

function App() {
  return (
    <div className="App">
      {/* <div className="div">Hello World</div>
      <div className="content">Lorem Ipsum</div>
      <Button>Button 1</Button>
      <Alert key="primary" variant="primary">
        Some Text
      </Alert>
      <section className="footer">This is the footer</section>
      <ButtonGroup aria-label="Basic example">
        <Button variant="secondary">Left</Button>
        <Button variant="secondary">Middle</Button>
        <Button variant="secondary">Right</Button>
      </ButtonGroup>
      <Card>
        <Card.Body>This is some text within a card body.</Card.Body>
      </Card> */}
      <header>
        <LogoPic />
      </header>

      <main>
        <Main />
      </main>
    </div>
  );
}

export default App;
