import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Red from "./Netflix.png";
import Green from "./Hulu.jpg";
import Blue from "./PrimeVideo.jpg";
import "./Style.css";

const Main = () => {
  
  return (
    <div>
      <h1 className="greeting">Wanna Watch A Movie?</h1>
      <container className="items">
        <Card style={{ width: "18rem" }} className="card">
          <Card.Img variant="top" src={Red} className="cimg1" />
          <Card.Body className="cbody">
            <Card.Title className="ctitle1">NetFlix</Card.Title>
            <Card.Text className="ctext">
              NetFlix is an American streaming service that offers a film and
              television series library through distribution deals as well as
              its own productions
            </Card.Text>
            <a href="https://www.netflix.com" target="_blank" rel="noreferrer">
              <Button variant="primary">Watch Now</Button>
            </a>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }} className="card">
          <Card.Img variant="top" src={Green} className="cimg2" />
          <Card.Body className="cbody">
            <Card.Title className="ctitle2">Hulu</Card.Title>
            <Card.Text className="ctext">
              Hulu is an American subscription streaming service majority-owned
              by The Walt Disney Company, with Comcasts NBCUniversal holding a
              minority stake.
            </Card.Text>
            <a href="https://www.hulu.com" target="_blank" rel="noreferrer">
            <Button variant="primary">Watch Now</Button>
            </a>
          </Card.Body>
        </Card>
        <Card style={{ width: "18rem" }} className="card">
          <Card.Img variant="top" src={Blue} className="cimg3" />
          <Card.Body className="cbody">
            <Card.Title className="ctitle3">Prime Video</Card.Title>
            <Card.Text className="ctext">
              Prime Video is an American subscription video on-demand streaming
              and rental service of Amazon offered as a standalone service or as
              part of Amazon's Prime subscription.
            </Card.Text>
            <a
              href="https://www.amazon.com/gp/video/storefront?filterId=OFFER_FILTER=PRIME" target="_blank" rel="noreferrer">
              <Button variant="primary">Watch Now</Button>
            </a>
          </Card.Body>
        </Card>
      </container>
    </div>
  );
};

export default Main;
